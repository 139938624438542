import React from "react";

const Analytics = () => {
  return (
    <div className="w-full bg-white py-16 px-14">
      <div className="max-w-[1240px] mx-auto grid md:grid-cols-2">
        <img className="w-[500px] mx-auto my-4" src="laptop.jpeg" alt="hi" />
        <div className="flex flex-col justify-center">
          <p className="text-[#00df9a] font-bold">DATA ANALYTICS DASHBOARD</p>
          <h1 className="md:text-4xl sm:text-3xl font-bold py-2">Manage Data Analytics Centrally</h1>
          <p className="">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur
            natus, ea sunt minus, fugit odit blanditiis vero eveniet, excepturi
            veniam animi eligendi labore expedita sit id quasi omnis consectetur
            possimus.
          </p>
          <button className="bg-black text-[#00df9a] w-[200px] rounded-md font-medium my-6 mx-auto md:mx-0 py-3 ">Get Started</button>
        </div>
      </div>
    </div>
  );
};

export default Analytics;
